// React
import React, { Component } from "react";

// AWS
import { Auth } from "aws-amplify";

// Redux
import { connect } from "react-redux";
import { setUser } from "../../redux/actions/user";

// Utils
import { validateField } from "../../functions/validate-field.js";
import { validateForm } from "../../functions/validate-form.js";
import getUser from "../../functions/get-user";

// Components
import Button from "../../components/button";
import FloatingForm from "../../components/floating-form";
import Input from "../../components/input";
import PageHeader from "../../components/page-header";
import Snackbar from "../../components/snackbar";

// Styles
import "./login.css";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: {
        value:
          process.env.NODE_ENV !== "production" ? "stephen@fyresite.com" : "",
        valid: true,
      },
      password: {
        value: process.env.NODE_ENV !== "production" ? "Fyresite123!" : "",
        valid: true,
      },
      snackbarMessage: "",
      loading: false,
    };
  }

  handleChange(field, e) {
    let { value, valid } = validateField(field, e, this.state);

    this.setState({
      [field]: { value, valid },
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    const formObject = {
      email: this.state.email,
      password: this.state.password,
    };
    const { formValid, emptyFields } = validateForm(formObject);

    if (formValid) {
      this.setState({ loading: true });
      try {
        let response = await Auth.signIn(
          this.state.email.value,
          this.state.password.value
        );

        console.log(response);

        if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
          response = await Auth.completeNewPassword(
            response,
            this.state.password.value
          );

          response = await Auth.signIn(
            this.state.email.value,
            this.state.password.value
          );
        }

        const user = await getUser(response.attributes.sub);
        this.props.setUser(user);

        this.setState({ loading: false });
        this.props.history.push("/auth-codes");
      } catch (err) {
        this.setState({ snackbarMessage: err.message, loading: false });
      }
    } else {
      emptyFields.forEach((fieldName) => {
        this.setState({ [fieldName]: { value: "", valid: false } });
      });
    }
  }

  handleCloseSnack() {
    this.setState({ snackbarMessage: "" });
  }

  render() {
    return (
      <div id="login-page">
        {this.state.snackbarMessage.length > 0 ? (
          <Snackbar
            message={this.state.snackbarMessage}
            handleclose={this.handleCloseSnack.bind(this)}
          />
        ) : (
          ""
        )}
        <PageHeader />

        <FloatingForm
          title="Admin Login"
          onSubmit={this.handleSubmit.bind(this)}
        >
          <div>
            <Input
              id="email"
              type="email"
              label="Email Address"
              onChange={this.handleChange.bind(this, "email")}
              value={this.state.email.value}
              errortext={
                this.state.email.valid
                  ? ""
                  : "Please enter a valid Email Address."
              }
            />
          </div>
          <div>
            <Input
              id="password"
              type="password"
              label="Password"
              onChange={this.handleChange.bind(this, "password")}
              value={this.state.password.value}
              errortext={
                this.state.password.valid
                  ? ""
                  : "Please enter a valid Password."
              }
            />
          </div>

          <input ref="formSubmit" type="submit" className="none" />

          <div className="login-page-button-container">
            <Button
              text="LOGIN"
              onClick={this.handleSubmit.bind(this)}
              color="primary"
              className="login-button-responsive-style"
              disabled={this.state.loading}
              size="large"
            />
            <Button
              text="FORGOT PASSWORD"
              onClick={() => this.props.history.push("/forgot-password")}
              size="large"
            />
          </div>
        </FloatingForm>
      </div>
    );
  }
}

export default connect(null, { setUser })(Login);
