import React, { Component } from 'react';

import Button            from '../button';
import Dialog            from '../dialog';
import Input             from '../input';
import JSONSchemaEditor  from '../json-schema-editor';
// import { validateForm }  from '../../functions/validate-form.js';
import { validateField } from '../../functions/validate-field.js';

import './crisis-sheet-node-edit.css';

export default class CrisisSheetNodeEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title:        { value: '', valid: true },
      description:  { value: '', valid: true },
      instructions: []
    };
  }

  handleChange(field, e) {
    let { value, valid } = validateField(field, e, this.state);

    this.setState({
      [field]: { value, valid }
    });
  }

  handleSubmit(e) {
    if(e) e.preventDefault();

    // const formObject = { title: this.state.title };
    // const { formValid, emptyFields } = validateForm(formObject);
    //
    // if (formValid) {
    let nodeChanges = {
      title: this.state.title.value,
      description: this.state.description.value,
      instructions: typeof this.state.instructions !== 'string' ? JSON.stringify(this.state.instructions) : this.state.instructions,
      id: this.props.node.attributes.id
    };

    this.props.savenodedata(nodeChanges);
    // } else {
    //   emptyFields.forEach(fieldName => {
    //     this.setState({[fieldName]: {value: '', valid: false}});
    //   });
    // }
  }

  handleAddDecision() {
    this.props.addnewdecision(this.props.node.attributes.id);
  }

  handleDeleteDecision() {
    this.props.deletenode(this.props.node.attributes.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.node) {
      const { node } = nextProps;

      console.log(node);

      this.setState({
        title:        { value: node.name ? node.name : '', valid: true },
        description:  { value: node.attributes.description ? node.attributes.description : '', valid: true },
        instructions: node.attributes.instructions ? node.attributes.instructions : []
      });
    } else {
      this.setState({
        title:        { value: '', valid: true },
        description:  { value: '', valid: true },
        instructions: { value: '', valid: true }
      });
    }
  }

  updateInstructions(instructions) {
    this.setState({ instructions });
  }

  render() {
    if(!this.props.open) {
      return null;
    }

    let listOfDecisions = (<div style={{padding: '5px 0'}}>{'No decision branches...'}</div>);

    if (this.props.node.children && this.props.node.children.length > 0) {
      listOfDecisions = this.props.node.children.map(decision => {
        return (
          <div key={decision.id} style={{padding: '5px 0'}}>
            { decision.name }
          </div>
        );
      });
    }

    return (
      <Dialog title='Edit Crisis Sheet Decision' {...this.props}>

        <div>
          <Input
            multiline={true}
            id='title'
            label='Title'
            onChange={this.handleChange.bind(this, 'title')}
            value={this.state.title.value}
            errortext={this.state.title.valid ? '' : 'Please enter a title.'} />
        </div>

        <div>
          <Input
            multiline={true}
            id='description'
            label='Description'
            onChange={this.handleChange.bind(this, 'description')}
            value={this.state.description.value}
            errortext={''} />
        </div>

        <div className='crisis-sheet-edit-json-schema-container'>
          <JSONSchemaEditor
            crisisSheetIds={this.props.crisisSheetIds}
            title={'List of Instructions'}
            data={ this.state.instructions }
            update={ this.updateInstructions.bind(this) } />
        </div>

        <div className='crisis-sheet-edit-decision-container'>
          <span className='crisis-sheet-edit-decision-title'>
            {'List of Decisions:'}
          </span>
          <span className='crisis-sheet-edit-decision-text'>
            { listOfDecisions }
          </span>
        </div>

        <div className='crisis-sheet-edit-node-button-container'>
          <div>
            <Button
              text='SAVE'
              fullWidth={true}
              onClick={this.handleSubmit.bind(this)}
              color='primary' size='large' />
          </div>

          <div className='crisis-sheet-edit-button-responsive-spacing'>
            <Button
              text='DELETE'
              fullWidth={true}
              onClick={this.handleDeleteDecision.bind(this)}
              color='secondary' size='large' />
          </div>

          <div className='crisis-sheet-edit-button-responsive-style'>
            <Button
              text='ADD DECISION'
              fullWidth={true}
              onClick={this.handleAddDecision.bind(this)}
              color='default' size='large' />
          </div>
        </div>

      </Dialog>
    );
  }
}
