import React, { Component } from "react";
// import ReactDOM             from 'react-dom';
import uuid from "uuid";

import { withStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import styles from "./styles";

class CustomSelect extends Component {
  constructor(props) {
    super(props);

    if (!props.id) {
      this.id = uuid.v4();
    } else {
      this.id = props.id;
    }

    // this.state = {
    //     labelWidth: 0
    // };
  }

  // componentDidMount() {
  //     if (this.InputLabelRef) {
  //         this.setState({
  //             labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
  //         });
  //     }
  // }

  render() {
    const { classes, options, label, value, onChange, disabled } = this.props;
    // const { labelWidth } = this.state;

    // options.unshift({ value: '', label: '' });

    return (
      <FormControl
        className={classes.formControl}
        disabled={disabled}
        margin="normal"
      >
        <InputLabel htmlFor={this.id}>{label}</InputLabel>
        <Select
          disabled={disabled}
          value={value}
          onChange={onChange}
          inputProps={{
            name: this.id,
            id: this.id,
          }}
        >
          {options.map((option, index) => (
            <MenuItem value={option.value} key={`${option}-${index}`}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(CustomSelect);
