import React, { Component } from "react";
import { API } from "aws-amplify";
import AuthCodeIcon from "@material-ui/icons/VerifiedUser";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import AuthCodeView from "../../components/auth-code-view";
import AuthCodeCreate from "../../components/auth-code-create";
import FloatingAddButton from "../../components/FloatingAddButton";
import PageTitle from "../../components/page-title";
import Snackbar from "../../components/snackbar";
import Table from "../../components/table";
import Toolbar from "../../components/toolbar";

import "./auth-codes.css";

const ITEMS_PER_PAGE = 10;

export default class AuthCodes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rows: ITEMS_PER_PAGE,
      totalCount: ITEMS_PER_PAGE,
      listOfCodes: null,
      viewCode: null,
      addCode: false,
      loading: false,
      snackbarMessage: "",
    };
  }

  componentDidMount() {
    this.getListOfCodes();
  }

  getListOfCodes() {
    this.setState({ loading: true }, () => {
      let params = {
        body: {
          page: this.state.page,
          count: ITEMS_PER_PAGE,
        },
      };
      API.post("TenMinSavesAPI", "/v1/admin/auth-code/list", params)
        .then((response) => {
          if (response.message === "Success") {
            this.setState({
              listOfCodes: response.data,
              loading: false,
              totalCount: response.totalItems,
            });
          } else {
            this.setState({
              listOfCodes: null,
              loading: false,
              snackbarMessage: response.message,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({ snackbarMessage: err.message, loading: false });
        });
    });
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      if (!!event) {
        this.getListOfCodes();
      }
    });
  };

  handleViewCode(code) {
    this.setState({ viewCode: code });
  }

  handleCloseCode() {
    this.setState({ viewCode: null });
  }

  handleShowAddCode() {
    this.setState({ addCode: true });
  }

  handleCloseAddCode() {
    this.setState({ addCode: false });
  }

  handleRefresh() {
    this.getListOfCodes();
  }

  closeSnackbar() {
    this.setState({ snackbarMessage: "" });
  }

  setSnackbarMessage(message) {
    this.setState({ snackbarMessage: message });
  }

  render() {
    const headers = [
      { title: "Email" },
      { title: "Status" },
      { title: "Number of Devices" },
    ];

    const { rows, page, listOfCodes, totalCount, loading } = this.state;

    return (
      <div>
        <AuthCodeView
          setsnackbar={this.setSnackbarMessage.bind(this)}
          open={Boolean(this.state.viewCode)}
          codedata={this.state.viewCode}
          refreshdata={this.handleRefresh.bind(this)}
          onClose={this.handleCloseCode.bind(this)}
        />

        <AuthCodeCreate
          setsnackbar={this.setSnackbarMessage.bind(this)}
          refreshdata={this.handleRefresh.bind(this)}
          open={this.state.addCode}
          onClose={this.handleCloseAddCode.bind(this)}
        />

        {this.state.snackbarMessage.length > 0 ? (
          <Snackbar
            message={this.state.snackbarMessage}
            handleclose={this.closeSnackbar.bind(this)}
          />
        ) : (
          ""
        )}

        <Toolbar history={this.props.history} />

        <div className="page-max-width">
          <PageTitle
            text="Manage Authorization Codes"
            icon={<AuthCodeIcon style={{ height: 42, width: 42 }} />}
          />

          <div className="auth-code-table-width">
            <Table
              headers={headers}
              tablepagination={
                <TableRow>
                  <TablePagination
                    colSpan={headers.length}
                    count={totalCount}
                    rowsPerPage={rows}
                    rowsPerPageOptions={[rows]}
                    page={page || 0}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    onChangePage={this.handleChangePage}
                  />
                </TableRow>
              }
            >
              {!loading ? (
                listOfCodes && listOfCodes.length > 0 ? (
                  listOfCodes.map((authCode) => {
                    return (
                      <TableRow key={authCode.id}>
                        <TableCell>
                          <span
                            onClick={this.handleViewCode.bind(this, authCode)}
                            className="link-text"
                          >
                            {authCode.email}
                          </span>
                        </TableCell>
                        <TableCell>{authCode.status.toUpperCase()}</TableCell>
                        <TableCell>{authCode.deviceTokens.length}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length}>
                      {"No Authorization Codes found..."}
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length}>
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress size={100} />
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </Table>

            <FloatingAddButton onClick={this.handleShowAddCode.bind(this)} />
          </div>
        </div>
      </div>
    );
  }
}
