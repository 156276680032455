import React, { Component } from 'react';
import { Auth }             from 'aws-amplify';

import Button            from '../../components/button';
import FloatingForm      from '../../components/floating-form';
import Input             from '../../components/input';
import PageHeader        from '../../components/page-header';
import Snackbar          from '../../components/snackbar';
import { validateForm }  from '../../functions/validate-form.js';
import { validateField } from '../../functions/validate-field.js';

import './forgot-password.css';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: { value: '', valid: true },
      snackbarMessage: '',
      loading: false
    };
  }

  handleChange(field, e) {
    let { value, valid } = validateField(field, e, this.state);

    this.setState({
      [field]: { value, valid }
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const formObject = { email: this.state.email };
    const { formValid, emptyFields } = validateForm(formObject);

    if (formValid) {
      this.setState({ loading: true }, () => {
        Auth.forgotPassword(this.state.email.value)
          .then(response => {
            this.setState({ loading: false });
            this.props.history.push(`/new-password?email=${this.state.email.value}`);
          })
          .catch(err => {
            this.setState({ snackbarMessage: err.message, loading: false });
          });
      });
    } else {
      emptyFields.forEach(fieldName => {
        this.setState({[fieldName]: {value: '', valid: false}});
      });
    }
  }

  handleCloseSnack() {
    this.setState({ snackbarMessage: '' });
  }

  render() {
    return (
      <div id='forgot-password-page'>
        {
          this.state.snackbarMessage.length > 0 ?
            <Snackbar
              message={this.state.snackbarMessage}
              handleclose={this.handleCloseSnack.bind(this)} />
            :
            ''
        }
        <PageHeader />

        <FloatingForm title='Forgot Password?' onSubmit={this.handleSubmit.bind(this)}>
          <Input
            id='email'
            type='email'
            label='Email Address'
            onChange={this.handleChange.bind(this, 'email')}
            value={this.state.email.value}
            errortext={this.state.email.valid ? '' : 'Please enter a valid Email Address.'} />

          <input ref="formSubmit" type="submit" className='none' />

          <div className='login-page-button-container'>
            <Button
              text='RESET PASSWORD'
              onClick={this.handleSubmit.bind(this)}
              color='primary'
              className='login-button-responsive-style'
              disabled={this.state.loading}
              size='large' />
            <Button
              text='BACK TO LOGIN'
              onClick={() => this.props.history.push('/')}
              size='large' />
          </div>

        </FloatingForm>

      </div>
    );
  }
}
