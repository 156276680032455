import React       from 'react';
import Table       from '@material-ui/core/Table';
import TableBody   from '@material-ui/core/TableBody';
import TableCell   from '@material-ui/core/TableCell';
import TableHead   from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow    from '@material-ui/core/TableRow';

export default class StyledTable extends React.Component {
  render() {
    let headID = 0;

    return (
      <Table>

        <TableHead>
          <TableRow>
            {
              this.props.headers.map(header => {
                return <TableCell key={headID++}>{header.title}</TableCell>
              })
            }
          </TableRow>
          { this.props.tablesearchbar }
        </TableHead>

        <TableBody>
          { this.props.children }
        </TableBody>

        {
          this.props.tablepagination ?
            <TableFooter>
              {this.props.tablepagination}
            </TableFooter>
            :
            null
        }
      </Table>
    )
  }
}
