const styles = theme => ({
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: 120,
        width: '100%'
    },
    notchedOutline: {
        background: 'white',
        borderColor: '#EDF0F3',
        borderRadius: 12,
        borderWidth: 2
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    }
});

export default styles;