const styles = (theme) => ({
  root: {
    maxWidth: 800,
    margin: "0 auto",
    padding: "0 20px 20px",
  },
  noEmergencyDrugsMessage: {
    margin: "20px 0",
  },
  crisisSheetMobileTreeButton: {
    textAlign: "center",
    margin: "70px 0 30px 0",
  },
  crisisSheetDecisionTitle: {
    color: theme.palette.custom.blue,
    fontSize: 28,
    padding: "10px 0",
  },
  crisisSheetDecisionNote: {
    color: theme.palette.custom.darkGrey,
    fontSize: 16,
  },
});

export default styles;

// .crisis-sheet-view-width {
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 0 20px 20px;
// }

// .no-emergency-drugs-message {
//   margin: 20px 0 !important;
// }

// .crisis-sheet-button-container {
//   margin-top: 50px;
// }

// .crisis-sheet-mobile-tree-button {
//   text-align: center;
//   margin: 70px 0 30px 0;
// }

// .crisis-sheet-decision-title {
//   font-size: 28px;
//   color: var(--blue);

//   padding: 10px 0;
// }

// .crisis-sheet-decision-note {
//   color: var(--dark-gray);
//   font-size: 16px;
// }
