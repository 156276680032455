import React, { Component } from 'react';
import { Auth }             from 'aws-amplify';

import Button            from '../../components/button';
import FloatingForm      from '../../components/floating-form';
import Input             from '../../components/input';
import PageHeader        from '../../components/page-header';
import Snackbar          from '../../components/snackbar';
import { validateForm }  from '../../functions/validate-form.js';
import { validateField } from '../../functions/validate-field.js';
import queryString       from '../../functions/query-string.js';

import './new-password.css';

export default class NewPassword extends Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(props.location.search);

    if(!query.email) {
      props.history.push('/forgot-password');
    }

    this.state = {
      email:         query.email,
      code:          { value: '', valid: true },
      password:      { value: '', valid: true },
      passwordMatch: { value: '', valid: true },
      snackbarMessage: '',
      loading: false
    };
  }

  handleChange(field, e) {
    let { value, valid } = validateField(field, e, this.state);

    this.setState({
      [field]: { value, valid }
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const formObject = {
      code: this.state.code,
      password: this.state.password,
      passwordMatch: this.state.passwordMatch
    };
    const { formValid, emptyFields } = validateForm(formObject);

    if (formValid) {
      this.setState({ loading: true }, () => {
        Auth.forgotPasswordSubmit(this.state.email, this.state.code.value, this.state.password.value)
          .then(response => {
            this.setState({ loading: false });
            this.props.history.push('/');
          })
          .catch(err => {
            this.setState({ snackbarMessage: err.message, loading: false });
          });
      });
    } else {
      emptyFields.forEach(fieldName => {
        this.setState({[fieldName]: {value: '', valid: false}});
      });
    }
  }

  handleCloseSnack() {
    this.setState({ snackbarMessage: '' });
  }

  render() {
    return (
      <div id='new-password-page'>
        {
          this.state.snackbarMessage.length > 0 ?
            <Snackbar
              message={this.state.snackbarMessage}
              handleclose={this.handleCloseSnack.bind(this)} />
            :
            ''
        }
        <PageHeader />

        <FloatingForm title='New Password' onSubmit={this.handleSubmit.bind(this)}>
          <div>
            <Input
              id='code'
              type='number'
              label='Reset Code'
              onChange={this.handleChange.bind(this, 'code')}
              value={this.state.code.value}
              errortext={this.state.code.valid ? '' : 'Please enter a valid Code.'} />
          </div>
          <div>
            <Input
              id='password'
              type='password'
              label='Password'
              onChange={this.handleChange.bind(this, 'password')}
              value={this.state.password.value}
              errortext={this.state.password.valid ? '' : 'Please enter a valid Password.'} />
          </div>
          <div>
            <Input
              id='passwordMatch'
              type='password'
              label='PasswordMatch'
              onChange={this.handleChange.bind(this, 'passwordMatch')}
              value={this.state.passwordMatch.value}
              errortext={this.state.passwordMatch.valid ? '' : 'Password does not match.'} />
          </div>

          <input ref="formSubmit" type="submit" className='none' />

          <div className='login-page-button-container'>
            <Button
              text='CHANGE PASSWORD'
              onClick={this.handleSubmit.bind(this)}
              color='primary'
              className='login-button-responsive-style'
              disabled={this.state.loading}
              size='large' />
            <Button
              text='BACK TO LOGIN'
              onClick={() => this.props.history.push('/')}
              size='large' />
          </div>

        </FloatingForm>

      </div>
    );
  }
}
