// ============================================
// recursive function for finding parent object by key 'attributes'
// ============================================

export const findParentByAtt = function(searchObject, searchData) {

  var result = null;

  if(searchObject instanceof Array) {
    for(var i = 0; i < searchObject.length; i++) {
      result = findParentByAtt(searchObject[i], searchData);
      if (result) {
        break;
      }
    }
  }
  else {
    for(var property in searchObject) {
      // console.log(property + ': ' + searchObject[property]);
      if(property === 'attributes') {
        if(JSON.stringify(searchObject[property]) === JSON.stringify(searchData)) {
          return searchObject;
        }
      }
      if(searchObject[property] instanceof Object || searchObject[property] instanceof Array) {
        result = findParentByAtt(searchObject[property], searchData);
        if (result) {
          break;
        }
      }
    }
  }
  // by this point, we found the parent object!
  return result;
}
