import React, { Component } from 'react';

import './page-title.css';

export default class PageTitle extends Component {
  render() {
    return (
      <div className='page-title-container'>
        <div className='hide-when-small'>
          {this.props.icon}
        </div>
        <span className='page-title-text'>
          {this.props.text}
        </span>
        {
          this.props.button ?
            <div className='page-title-button-container'>
              { this.props.button }
            </div>
            :
            null
        }
      </div>
    );
  }
}
