import React, { Component } from 'react';
import CloseIcon            from '@material-ui/icons/Close';
import IconButton           from '@material-ui/core/IconButton';
import Snackbar             from '@material-ui/core/Snackbar';

const AUTO_HIDE_TIME = 5 * 1000; // 5 seconds

export default class StyledSnackbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open
    };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.handleclose();
  };

  render() {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={AUTO_HIDE_TIME}
        onClose={this.handleClose}
        message={
          <span>
            {this.props.message}
          </span>
        }
        action={
          <IconButton
            color="inherit"
            aria-label="close snackbar"
            onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
    )
  }
}
