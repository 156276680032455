// React
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// AWS
import { Auth } from "aws-amplify";

// Redux
import { connect } from "react-redux";
import { clearUser, setUser } from "../../redux/actions/user";

// Utils
import getUser from "../../functions/get-user";

class PublicRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticationComplete: false,
    };
  }

  componentDidMount() {
    this.authenticate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.authenticationComplete === false) {
      this.authenticate();
    }
  }

  authenticate() {
    Auth.currentUserInfo()
      .then((user) => {
        if (user) {
          this.props.history.replace({
            pathname: "/auth-codes",
            search: "",
            state: { user },
          });
        } else {
          this.setState({ authenticationComplete: true });
        }
      })
      .catch((err) => {
        console.error(err);
        this.props.clearUser();
        Auth.signOut();
      });
  }

  render() {
    let Comp = this.props.component;

    return <Comp {...this.props} />;
  }
}

export default withRouter(connect(null, { clearUser, setUser })(PublicRoute));
