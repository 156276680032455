// React
import React, { Component } from "react";

// Libraries
import checkError from "@fyresite/check-error";
import ISO6391 from "iso-639-1";

// AWS
import { API } from "aws-amplify";

// Redux
import { connect } from "react-redux";

// Utils
import TenMinutes from "../../modules/TenMinutes";
import { validateForm } from "../../functions/validate-form.js";
import { validateField } from "../../functions/validate-field.js";
import queryString from "../../functions/query-string.js";

// Material UI
import { withStyles, CircularProgress, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

// Components
import Button from "../../components/button";
import ConfirmDialog from "../../components/confirm-dialog";
import CrisisSheetDiagram from "../../components/crisis-sheet-diagram";
import CustomSelect from "../../components/CustomSelect";
import EmergencyDrugsAttached from "../../components/EmergencyDrugsAttached";
import Input from "../../components/input";
import PageTitle from "../../components/page-title";
import Snackbar from "../../components/snackbar";
import Toolbar from "../../components/toolbar";

// Styles
import styles from "./styles.js";

class CrisisSheetView extends Component {
  constructor(props) {
    super(props);

    const allLanguages = this.setAvailableLanguages();

    this.state = {
      title: { value: "", valid: true },
      providerLevel: { value: "", valid: true },
      evaluationLevel: { value: "", valid: true },
      shortDescription: { value: "", valid: true },
      longDescription: { value: "", valid: true },
      order: { value: "", valid: true },
      language: { value: "", valid: true },
      allLanguages,
      crisisSheets: [],
      crisisSheetData: {},
      emergencyDrugData: [],
      showConfirm: false,
      id: null,
      version: null,
      loading: true,
      loadingButton: false,
      snackbarMessage: "",
      preExistingSheet: true,
      width: window.innerWidth,
      showMobileCrisisTree: false,
      emergencyDrugs: null,
      originalSheet: {},
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }
  /**
   * Lifecycle methods
   */

  async componentDidMount() {
    // Init/add window resize event listener
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);

    const query = queryString.parse(this.props.location.search);

    if (!query.id) {
      this.setState({ loading: false, preExistingSheet: false });
      return;
    }

    const myInit = {};

    try {
      // Get all necessary data from the API
      let crisisSheet = await TenMinutes.getCrisisSheet(query.id, 0);
      myInit.body = { language: crisisSheet.language };

      const crisisSheets = await TenMinutes.getCrisisSheets(myInit);
      const emergencyDrugs = await TenMinutes.getEmergencyDrugList(myInit);

      let preExistingSheet = true;

      // This could be fixed by using redux...
      if (typeof crisisSheet === "undefined") {
        preExistingSheet = false;
        crisisSheet = {
          title: "",
          order: "",
          language: "",
          providerLevel: "",
          evaluationLevel: "",
          shortDescription: "",
          longDescription: "",
          data: {},
          emergencyDrugData: [],
          version: 1,
        };
      }

      this.setState({
        crisisSheets,
        emergencyDrugs,
        preExistingSheet,
        loading: false,
        title: { value: crisisSheet.title, valid: true },
        order: { value: crisisSheet.order || "", valid: true },
        language: {
          value: ISO6391.getName(crisisSheet.language) || "",
          valid: true,
        },
        providerLevel: { value: crisisSheet.providerLevel, valid: true },
        evaluationLevel: { value: crisisSheet.evaluationLevel, valid: true },
        shortDescription: {
          value: crisisSheet.shortDescription,
          valid: true,
        },
        longDescription: { value: crisisSheet.longDescription, valid: true },
        crisisSheetData: crisisSheet.data,
        emergencyDrugData: crisisSheet.emergencyDrugs || [],
        id: crisisSheet.id,
        version: crisisSheet.version,
        originalSheet: crisisSheet,
      });
    } catch (err) {
      console.log(err);
      this.setState({ snackbarMessage: checkError(err) });
    }
  }

  async componentDidUpdate(_, prevState) {
    if (prevState.language !== this.state.language) {
      // console.log(this.state.language);
      try {
        const myInit = {
          body: {
            language: ISO6391.getCode(this.state.language.value),
          },
        };
        const crisisSheets = await TenMinutes.getCrisisSheets(myInit);
        const emergencyDrugs = await TenMinutes.getEmergencyDrugList(myInit);

        this.setState({ crisisSheets, emergencyDrugs });
      } catch (err) {
        this.setState({ snackbarMessage: checkError(err) });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  /**
   * Private Methods
   */
  setAvailableLanguages() {
    if (this.props.user.languages.includes("*")) {
      return ISO6391.getAllNames().map((language) => ({
        value: language,
        label: language,
      }));
    }

    return this.props.user.languages.map((code) => ({
      value: ISO6391.getName(code),
      label: ISO6391.getName(code),
    }));
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  filterSheet(sheet) {
    const { evaluationLevel, providerLevel, id } = this.state;
    return (
      sheet.evaluationLevel === parseInt(evaluationLevel.value, 10) &&
      sheet.providerLevel === parseInt(providerLevel.value, 10) &&
      sheet.id !== id
    );
  }

  /**
   * Gets the options for the "Order" dropdown list
   */
  getOrderList() {
    let orderSelectData = [{ label: 1, value: 1 }];
    let count = 1;

    this.state.crisisSheets.forEach((sheet) => {
      if (
        sheet.evaluationLevel ===
          parseInt(this.state.evaluationLevel.value, 10) &&
        sheet.providerLevel === parseInt(this.state.providerLevel.value, 10) &&
        this.state.id !== sheet.id
      ) {
        count += 1;

        orderSelectData.push({ label: count, value: count });
      }
    });

    return orderSelectData;
  }

  closeSnackbar() {
    this.setState({ snackbarMessage: "" });
  }

  toggleShowCrisisTree() {
    this.setState({ showMobileCrisisTree: !this.state.showMobileCrisisTree });
  }

  setSnackbarMessage(message) {
    this.setState({ snackbarMessage: message });
  }

  /**
   * Event Handlers
   */

  handleChange(field, e) {
    let { value, valid } = validateField(field, e, this.state);

    this.setState(
      {
        [field]: { value, valid },
      },
      () => {
        // Preserver the order field
        if (field === "providerLevel" || field === "evaluationLevel") {
          if (
            this.state.originalSheet.providerLevel ===
              parseInt(this.state.providerLevel.value, 10) &&
            this.state.originalSheet.evaluationLevel ===
              parseInt(this.state.evaluationLevel.value, 10) &&
            this.state.originalSheet.order !== this.state.order.value
          ) {
            this.setState({
              order: { value: this.state.originalSheet.order, valid: true },
            });
          } else {
            let count = 1;
            this.state.crisisSheets.forEach((sheet) => {
              if (
                sheet.evaluationLevel ===
                  parseInt(this.state.evaluationLevel.value, 10) &&
                sheet.providerLevel ===
                  parseInt(this.state.providerLevel.value, 10) &&
                this.state.id !== sheet.id
              ) {
                count += 1;
              }
            });
            this.setState({
              order: { value: count, valid: true },
            });
          }
        }
      }
    );
  }

  async handleSubmit(e) {
    e.preventDefault();

    const formObject = {
      title: this.state.title,
      providerLevel: this.state.providerLevel,
      evaluationLevel: this.state.evaluationLevel,
      shortDescription: this.state.shortDescription,
      longDescription: this.state.longDescription,
    };
    const { formValid, emptyFields } = validateForm(formObject);

    if (formValid) {
      this.setState({ loadingButton: true });

      let crisisSheet = null;

      crisisSheet = {
        title: this.state.title.value,
        providerLevel: this.state.providerLevel.value,
        evaluationLevel: this.state.evaluationLevel.value,
        shortDescription: this.state.shortDescription.value,
        longDescription: this.state.longDescription.value,
        data: this.state.crisisSheetData,
        emergencyDrugs: this.state.emergencyDrugData,
        order: this.state.order.value,
        language: ISO6391.getCode(this.state.language.value),
      };

      if (this.state.preExistingSheet) {
        crisisSheet.id = this.state.id;

        const response = await TenMinutes.updateCrisisSheet(crisisSheet);

        this.setState({ snackbarMessage: response.message });
      } else {
        const response = await TenMinutes.createCrisisSheet(crisisSheet);

        this.setState({ snackbarMessage: response.message });
      }

      this.setState({ loadingButton: false });
    } else {
      emptyFields.forEach((fieldName) => {
        this.setState({ [fieldName]: { value: "", valid: false } });
      });
    }
  }

  handleDelete() {
    this.setState({ showConfirm: true });
  }

  handleDeleteClose() {
    this.setState({ showConfirm: false });
  }

  handleDeleteConfirm() {
    const { id, version } = this.state;

    API.del(
      "TenMinSavesAPI",
      `/v1/admin/crisis-sheet?id=${id}&version=${version}`
    )
      .then((response) => {
        if (response.message === "Success") {
          // take them back to the crisis sheets list
          this.props.history.goBack();
        } else {
          this.setSnackbarMessage(response.message);
          this.handleDeleteClose();
        }
      })
      .catch((err) => {
        console.log(err);
        this.setSnackbarMessage(err.message);
      });
  }

  handleUpdateData(newData) {
    this.setState({ crisisSheetData: newData });
  }

  handleUpdateDrugs(emergencyDrugData) {
    this.setState({ emergencyDrugData });
  }

  /**
   * Render method
   */

  render() {
    const { classes } = this.props;
    const {
      allLanguages,
      crisisSheetData,
      crisisSheets,
      emergencyDrugData,
      emergencyDrugs,
      evaluationLevel,
      id,
      language,
      loading,
      loadingButton,
      longDescription,
      order,
      originalSheet,
      preExistingSheet,
      providerLevel,
      shortDescription,
      showConfirm,
      snackbarMessage,
      title,
      width,
    } = this.state;

    console.log(crisisSheetData);

    return (
      <React.Fragment>
        <ConfirmDialog
          open={showConfirm}
          onClose={this.handleDeleteClose.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        />

        {snackbarMessage.length > 0 ? (
          <Snackbar
            message={snackbarMessage}
            handleclose={this.closeSnackbar.bind(this)}
          />
        ) : (
          ""
        )}

        <Toolbar
          backbutton={true}
          route="/crisis-sheets"
          history={this.props.history}
        />

        <div className="page-max-width">
          <PageTitle
            text={preExistingSheet ? "Edit Crisis Sheet" : "New Crisis Sheet"}
            icon={<EditIcon style={{ height: 42, width: 42 }} />}
            button={
              <React.Fragment>
                <Button
                  text="SAVE"
                  onClick={this.handleSubmit.bind(this)}
                  color="primary"
                  disabled={loadingButton}
                  size="large"
                />
                {preExistingSheet ? (
                  <Button
                    style={{ marginLeft: 10 }}
                    text="DELETE"
                    onClick={this.handleDelete.bind(this)}
                    color="secondary"
                    disabled={loadingButton}
                    size="large"
                  />
                ) : null}
              </React.Fragment>
            }
          />

          <div className={classes.root}>
            {loading ? (
              <div style={{ textAlign: "center", paddingTop: 50 }}>
                <CircularProgress size={150} />
              </div>
            ) : (
              <form onSubmit={this.handleSubmit.bind(this)}>
                <div>
                  <Input
                    id="title"
                    label="Title"
                    multiline={true}
                    onChange={this.handleChange.bind(this, "title")}
                    value={title.value}
                    errortext={title.valid ? "" : "Please enter a title."}
                  />
                </div>
                <div>
                  <Input
                    id="providerLevel"
                    type="number"
                    label="Provider Level"
                    onChange={this.handleChange.bind(this, "providerLevel")}
                    value={providerLevel.value}
                    errortext={
                      providerLevel.valid
                        ? ""
                        : "Please enter a number between 1 and 3."
                    }
                  />
                </div>
                <div>
                  <Input
                    id="evaluationLevel"
                    type="number"
                    label="Evaluation Level"
                    onChange={this.handleChange.bind(this, "evaluationLevel")}
                    value={evaluationLevel.value}
                    errortext={
                      evaluationLevel.valid
                        ? ""
                        : "Please enter a number between 1 and 3."
                    }
                  />
                </div>
                <div>
                  <CustomSelect
                    label="Order"
                    disabled={
                      !preExistingSheet ||
                      parseInt(evaluationLevel.value, 10) !==
                        originalSheet.evaluationLevel ||
                      parseInt(providerLevel.value, 10) !==
                        originalSheet.providerLevel
                    }
                    onChange={this.handleChange.bind(this, "order")}
                    value={order.value}
                    options={this.getOrderList()}
                  />
                </div>
                <div>
                  <CustomSelect
                    disabled={!!id}
                    label="Languages"
                    onChange={this.handleChange.bind(this, "language")}
                    value={language.value}
                    options={allLanguages}
                  />
                </div>
                <div>
                  <Input
                    id="shortDescription"
                    label="Short Description"
                    multiline={true}
                    onChange={this.handleChange.bind(this, "shortDescription")}
                    value={shortDescription.value}
                    errortext={
                      shortDescription.valid
                        ? ""
                        : "Please enter a short description."
                    }
                  />
                </div>
                <div>
                  <Input
                    id="longDescription"
                    label="Long Description"
                    multiline={true}
                    rowsMax={10}
                    onChange={this.handleChange.bind(this, "longDescription")}
                    value={longDescription.value}
                    errortext={
                      longDescription.valid
                        ? ""
                        : "Please enter a long description."
                    }
                  />
                </div>

                {!language.value ? (
                  <Typography
                    variant="title"
                    classes={{ root: classes.noEmergencyDrugsMessage }}
                  >
                    Please select a language before attaching emergency drugs
                  </Typography>
                ) : (
                  <EmergencyDrugsAttached
                    update_drugs={this.handleUpdateDrugs.bind(this)}
                    data={emergencyDrugData}
                    emergency_drugs={emergencyDrugs}
                  />
                )}

                <input ref="formSubmit" type="submit" className="none" />

                <div className={classes.crisisSheetDecisionTitle}>
                  {"Decision Editor"}
                </div>
                <div className={classes.crisisSheetDecisionNote}>
                  {"NOTE: The instructions use "}
                  <a
                    href="https://www.markdownguide.org/basic-syntax/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"Markdown Syntax"}
                  </a>
                  {" for adding links, styling text, and adding images."}
                </div>

                {width > 600 ? (
                  <CrisisSheetDiagram
                    snackbarMessage={this.setSnackbarMessage.bind(this)}
                    crisisSheetIds={crisisSheets}
                    data={crisisSheetData}
                    update={this.handleUpdateData.bind(this)}
                  />
                ) : (
                  <div className={classes.crisisSheetMobileTreeButton}>
                    <Button
                      text="Edit Decision Tree"
                      onClick={this.toggleShowCrisisTree.bind(this)}
                      color="primary"
                      size="large"
                    />
                  </div>
                )}

                {/* <MobileCrisisSheetDiagram
                    snackbar_message={this.setSnackbarMessage.bind(this)}
                    crisisSheetIds={crisisSheets}
                    open={showMobileCrisisTree}
                    onClose={this.toggleShowCrisisTree.bind(this)}
                    data={crisisSheetData}
                    update={this.handleUpdateData.bind(this)} /> */}
              </form>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(withStyles(styles)(CrisisSheetView));
