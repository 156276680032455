import React, { Component } from 'react';
import { API }              from 'aws-amplify';

import Checkbox from '@material-ui/core/Checkbox';

import Button            from '../button';
import Dialog            from '../dialog';
import Input             from '../input';
import { validateForm }  from '../../functions/validate-form.js';
import { validateField } from '../../functions/validate-field.js';

import './auth-code-create.css';

export default class AuthCodeCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: { value: '', valid: true },
      unlimitedDevices: false,
      loading: false
    };
  }

  handleChange(field, e) {
    let { value, valid } = validateField(field, e, this.state);

    this.setState({
      [field]: { value, valid }
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const formObject = { email: this.state.email };
    const { formValid, emptyFields } = validateForm(formObject);

    if (formValid) {
      this.setState({ loading: true }, () => {
        let params = {
          body: {
            email: this.state.email.value,
            deviceLimit: this.state.unlimitedDevices ? -1 : 10
          }
        };
        API.post('TenMinSavesAPI', '/v1/admin/auth-code/create', params)
          .then(response => {
            if(response.message === 'Success') {
              this.props.onClose();
              this.props.refreshdata();
              this.setState({
                email: { value: '', valid: true },
                loading: false
              });
            } else {
              this.setState({ loading: false });
              this.props.setsnackbar(response.message);
            }
          })
          .catch(err => {
            console.error(err);
            this.props.setsnackbar(err.message);
            this.setState({ loading: false });
          });
      });
    } else {
      emptyFields.forEach(fieldName => {
        this.setState({[fieldName]: {value: '', valid: false}});
      });
    }
  }

  render() {
    return (
      <Dialog title='Create New Auth Code' {...this.props}>

        <form onSubmit={this.handleSubmit.bind(this)}>
          <div>
            <Input
              id='email'
              type='email'
              label='Email Address'
              onChange={this.handleChange.bind(this, 'email')}
              value={this.state.email.value}
              errortext={this.state.email.valid ? '' : 'Please enter a valid email address.'} />
          </div>

          <label htmlFor="unlimited">Unlimited Devices</label>

          <Checkbox
            id="unlimited"
            checked={this.state.unlimitedDevices}
            onChange={e => this.setState({ unlimitedDevices: !this.state.unlimitedDevices })} />
          <input ref="formSubmit" type="submit" className='none' />

          <div className='auth-code-create-button-container'>
            <Button
              text='CREATE CODE'
              onClick={this.handleSubmit.bind(this)}
              disabled={this.state.loading}
              color='primary' size='large' />
          </div>
        </form>

      </Dialog>
    );
  }
}
