import React, { useState } from 'react';
import ReactMde            from 'react-mde';
import Showdown            from 'showdown';

import './styles.css';

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

const MarkdownField = ({ formData, onChange }) => {

  const [currTab, setCurrTab] = useState('write');

  return (
    <div className='markdown-field__container'>
      <ReactMde
        onChange={(value) => onChange(value)}
        value={formData}
        onTabChange={setCurrTab}
        selectedTab={currTab}
        generateMarkdownPreview={markdown =>
          Promise.resolve(converter.makeHtml(markdown))
        } />
    </div>
  );
};

export default MarkdownField;