import React from 'react';

import Button from '../button';
import Dialog from '../dialog';

import './confirm-dialog.css';

const ConfirmDialog = (props) => {
  return(
    <Dialog
      title={'Are you sure?'}
      open={props.open}
      onClose={props.onClose}>

      <div className='confirm-dialog-text'>
        {'This action cannot be undone...'}
      </div>

      <div className='confirm-dialog-buttons'>
        <Button
          text='CANCEL'
          onClick={props.onClose}
          color='secondary'
          size='large' />

        <Button
          text='CONFIRM'
          onClick={props.onConfirm}
          color='primary'
          size='large' />
      </div>

    </Dialog>
  );
}

export default ConfirmDialog;
