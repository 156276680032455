import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import ISO6391 from "iso-639-1";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import SaveIcon from "@material-ui/icons/Save";

import "./toxicity.css";

const ToxicitySheetSelectors = (props) => {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sheetIds, setSheetIds] = useState([]);
  const [toxSheet1, setToxSheet1] = useState("");
  const [toxSheet2, setToxSheet2] = useState("");
  const [toxSheet3, setToxSheet3] = useState("");

  // get list of sheets with ids on mount
  useEffect(() => {
    if (props.makeCall) {
      setLoading(true);
      let params = {
        body: {
          ids_only: true,
          language: ISO6391.getCode(props.language),
        },
      };
      API.post("TenMinSavesAPI", "/v1/admin/crisis-sheet/list", params)
        .then((response) => {
          if (response.message === "Success") {
            setLoading(false);
            setSheetIds(response.data);
          } else {
            setLoading(false);
            setSheetIds([]);
            props.setSnackbar(response.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          props.setSnackbar(err.message);
        });
    }
  }, [props.makeCall, props.language]);

  function handleUpdateLaToxicitySheet(providerLevel) {
    let currSheet;

    switch (providerLevel) {
      case 1:
        currSheet = toxSheet1;
        break;
      case 2:
        currSheet = toxSheet2;
        break;
      case 3:
        currSheet = toxSheet3;
        break;
      default:
    }

    if (currSheet !== "") {
      let params = {
        body: {
          id: currSheet,
          providerLevel: providerLevel,
          language: ISO6391.getCode(props.language),
        },
      };
      setSaveLoading(true);
      API.post(
        "TenMinSavesAPI",
        "/v1/admin/crisis-sheet/toxicity-sheet",
        params
      )
        .then((response) => {
          // console.log(response);
          setSaveLoading(false);
          props.setSnackbar("Toxicity sheet updated.");
        })
        .catch((err) => {
          console.log(err);
          setSaveLoading(false);
          props.setSnackbar(err.message);
        });
    }
  }

  let initialSelectValue1 = (
    <option key={"some-key-1"} value={""}>
      Please select a Crisis Sheet
    </option>
  );
  let initialSelectValue2 = (
    <option key={"some-key-2"} value={""}>
      Please select a Crisis Sheet
    </option>
  );
  let initialSelectValue3 = (
    <option key={"some-key-3"} value={""}>
      Please select a Crisis Sheet
    </option>
  );

  sheetIds.forEach((sheet) => {
    if (sheet.toxicity_sheet) {
      if (sheet.providerLevel === 1) {
        initialSelectValue1 = (
          <option key={sheet.id} value={sheet.id}>
            {JSON.stringify(sheet.providerLevel) + " - " + sheet.title}
          </option>
        );
      } else if (sheet.providerLevel === 2) {
        initialSelectValue2 = (
          <option key={sheet.id} value={sheet.id}>
            {JSON.stringify(sheet.providerLevel) + " - " + sheet.title}
          </option>
        );
      } else if (sheet.providerLevel === 3) {
        initialSelectValue3 = (
          <option key={sheet.id} value={sheet.id}>
            {JSON.stringify(sheet.providerLevel) + " - " + sheet.title}
          </option>
        );
      }
    }
  });

  let sheetSelectOptions1 = [initialSelectValue1];
  let sheetSelectOptions2 = [initialSelectValue2];
  let sheetSelectOptions3 = [initialSelectValue3];

  sheetSelectOptions1.push(
    sheetIds.map((sheet, index) => {
      if (
        sheet.id !== initialSelectValue1.props.value &&
        sheet.providerLevel === 1
      ) {
        return (
          <option key={`la-toxicity-${sheet.id}`} value={sheet.id}>
            {sheet.providerLevel + " - " + sheet.title}
          </option>
        );
      }
      return null;
    })
  );

  sheetSelectOptions2.push(
    sheetIds.map((sheet, index) => {
      if (
        sheet.id !== initialSelectValue2.props.value &&
        sheet.providerLevel === 2
      ) {
        return (
          <option key={`la-toxicity-${sheet.id}`} value={sheet.id}>
            {sheet.providerLevel + " - " + sheet.title}
          </option>
        );
      }
      return null;
    })
  );

  sheetSelectOptions3.push(
    sheetIds.map((sheet, index) => {
      if (
        sheet.id !== initialSelectValue3.props.value &&
        sheet.providerLevel === 3
      ) {
        return (
          <option key={`la-toxicity-${sheet.id}`} value={sheet.id}>
            {sheet.providerLevel + " - " + sheet.title}
          </option>
        );
      }
      return null;
    })
  );

  if (loading || props.makeCall === false) {
    return (
      <Paper className="la-toxicity" style={{ justifyContent: "center" }}>
        <CircularProgress size={100} />
      </Paper>
    );
  }

  return (
    <React.Fragment>
      <Paper className="la-toxicity">
        <div className="la-toxicity-form-input">
          <label htmlFor="la-toxicity-select">
            LA Toxicity Crisis Sheet (Level 1)
          </label>
          <select
            id="la-toxicity-select"
            onChange={(e) => setToxSheet1(e.target.value)}
            value={toxSheet1}
          >
            {sheetSelectOptions1}
          </select>
        </div>
        <Button
          disabled={saveLoading}
          variant="contained"
          color="primary"
          onClick={() => handleUpdateLaToxicitySheet(1)}
        >
          <SaveIcon />
        </Button>
      </Paper>

      <Paper className="la-toxicity">
        <div className="la-toxicity-form-input">
          <label htmlFor="la-toxicity-select">
            LA Toxicity Crisis Sheet (Level 2)
          </label>
          <select
            id="la-toxicity-select"
            onChange={(e) => setToxSheet2(e.target.value)}
            value={toxSheet2}
          >
            {sheetSelectOptions2}
          </select>
        </div>
        <Button
          disabled={saveLoading}
          variant="contained"
          color="primary"
          onClick={() => handleUpdateLaToxicitySheet(2)}
        >
          <SaveIcon />
        </Button>
      </Paper>

      <Paper className="la-toxicity">
        <div className="la-toxicity-form-input">
          <label htmlFor="la-toxicity-select">
            LA Toxicity Crisis Sheet (Level 3)
          </label>
          <select
            id="la-toxicity-select"
            onChange={(e) => setToxSheet3(e.target.value)}
            value={toxSheet3}
          >
            {sheetSelectOptions3}
          </select>
        </div>
        <Button
          disabled={saveLoading}
          variant="contained"
          color="primary"
          onClick={() => handleUpdateLaToxicitySheet(3)}
        >
          <SaveIcon />
        </Button>
      </Paper>
    </React.Fragment>
  );
};

export default ToxicitySheetSelectors;
