// React
import React, { Component } from "react";

// Libraries
import ISO6391 from "iso-639-1";

// AWS
import { API } from "aws-amplify";

// Redux
import { connect } from "react-redux";

// Utils
import alphaSort from "../../functions/alpha-sort";

// Material UI
import CircularProgress from "@material-ui/core/CircularProgress";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

// Components
import CustomSelect from "../../components/CustomSelect";
import EmergencyDrugCreate from "../../components/emergency-drug-create";
import FloatingAddButton from "../../components/FloatingAddButton";
import PageTitle from "../../components/page-title";
import Snackbar from "../../components/snackbar";
import Table from "../../components/table";
import Toolbar from "../../components/toolbar";

// Styles
import "./emergency-drugs.css";

class EmergencyDrugs extends Component {
  constructor(props) {
    super(props);

    const allLanguages = this.setAvailableLanguages();
    const language = "English"; //allLanguages.length ? allLanguages[0].value : "";

    this.state = {
      listOfDrugs: null,
      viewDrug: null,
      addDrug: false,
      loading: false,
      snackbarMessage: "",
      language,
      allLanguages,
    };

    this.getListOfDrugs = this.getListOfDrugs.bind(this);
  }

  componentWillMount() {
    this.getListOfDrugs();
  }

  setAvailableLanguages() {
    if (this.props.user.languages.includes("*")) {
      return ISO6391.getAllNames().map((language) => ({
        value: language,
        label: language,
      }));
    }

    return this.props.user.languages.map((code) => ({
      value: ISO6391.getName(code),
      label: ISO6391.getName(code),
    }));
  }

  getListOfDrugs(config = { language: ISO6391.getCode(this.state.language) }) {
    this.setState({ loading: true }, () => {
      API.post("TenMinSavesAPI", "/v1/admin/emergency-drugs/list", {
        body: {
          ...config,
        },
      })
        .then((response) => {
          if (response.message === "Success") {
            const orderedDrugData = alphaSort(response.data, "name");

            this.setState({ listOfDrugs: orderedDrugData, loading: false });
          } else {
            this.setState({
              listOfDrugs: null,
              loading: false,
              snackbarMessage: response.message,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({ snackbarMessage: err.message, loading: false });
        });
    });
  }

  handleChange(field, e) {
    this.setState({ [field]: e.target.value });
    if (field === "language") {
      this.getListOfDrugs({ language: ISO6391.getCode(e.target.value) });
    }
  }

  handleViewDrug(drugInfo) {
    this.setState({ viewDrug: drugInfo });
  }

  handleCloseDrug() {
    this.setState({ viewDrug: null });
  }

  handleShowAddDrug() {
    this.setState({ addDrug: true });
  }

  handleCloseAddDrug() {
    this.setState({ addDrug: false });
  }

  handleRefresh() {
    this.getListOfDrugs();
  }

  closeSnackbar() {
    this.setState({ snackbarMessage: "" });
  }

  setSnackbarMessage(message) {
    this.setState({ snackbarMessage: message });
  }

  render() {
    const headers = [
      { title: "Name" },
      { title: "Note" },
      { title: "Dosage" },
      { title: "Information" },
      { title: "Provider Level" },
    ];

    const {
      viewDrug,
      addDrug,
      listOfDrugs,
      loading,
      snackbarMessage,
      language,
      allLanguages,
    } = this.state;

    return (
      <div>
        <EmergencyDrugCreate
          setsnackbar={this.setSnackbarMessage.bind(this)}
          open={Boolean(viewDrug)}
          drug_data={viewDrug}
          refreshdata={this.handleRefresh.bind(this)}
          onClose={this.handleCloseDrug.bind(this)}
        />

        <EmergencyDrugCreate
          setsnackbar={this.setSnackbarMessage.bind(this)}
          refreshdata={this.handleRefresh.bind(this)}
          open={addDrug}
          onClose={this.handleCloseAddDrug.bind(this)}
          language={language}
        />

        {snackbarMessage.length > 0 ? (
          <Snackbar
            message={snackbarMessage}
            handleclose={this.closeSnackbar.bind(this)}
          />
        ) : (
          ""
        )}

        <Toolbar history={this.props.history} />

        <div className="page-max-width">
          <PageTitle
            text="Manage Emergency Drugs"
            icon={<i className="fa fa-medkit" style={{ fontSize: 36 }} />}
          />
          <div className="emergency-drugs-table-width">
            <div className="select-language">
              <CustomSelect
                label="Select Language"
                onChange={this.handleChange.bind(this, "language")}
                value={language}
                options={allLanguages}
              />
            </div>
            <Table headers={headers}>
              {!loading ? (
                listOfDrugs && listOfDrugs.length > 0 ? (
                  listOfDrugs.map((drug) => {
                    return (
                      <TableRow key={drug.id}>
                        <TableCell>
                          <span
                            onClick={this.handleViewDrug.bind(this, drug)}
                            className="link-text"
                          >
                            {drug.name}
                          </span>
                        </TableCell>
                        <TableCell>{drug.note}</TableCell>
                        <TableCell>
                          {drug.dosageAmount + " - " + drug.dosageUnit}
                        </TableCell>
                        <TableCell>{drug.information}</TableCell>
                        <TableCell>{drug.providerLevel || "MISSING"}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length}>
                      {"No Emergency Drugs found..."}
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length}>
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress size={100} />
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </Table>

            <FloatingAddButton onClick={this.handleShowAddDrug.bind(this)} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(EmergencyDrugs);
