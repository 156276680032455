import React, { Component } from 'react';
import Form                 from 'react-jsonschema-form';

import MarkdownField from '../markdown-field';

import './json-schema-editor.css';

export default class JsonSchemaEditor extends Component {
  constructor(props) {
    super(props);

    this.handleReplaceGlyphicons = this.handleReplaceGlyphicons.bind(this);
  }

  componentDidMount() {
    this.handleReplaceGlyphicons();
  }

  handleReplaceGlyphicons() {
    // replace glyphicons with font-awesome icons
    let iconNodes = document.getElementsByClassName('glyphicon');
    let length = iconNodes.length;
    let changedClasses = false;

    for(let i = 0; i < length; i ++) {
      if(iconNodes[i]) {
        switch(iconNodes[i].className) {
          case 'glyphicon glyphicon-remove':
            changedClasses = true;
            iconNodes[i].className = 'fa fa-trash json-schema-editor-icon';
            break;
          case 'glyphicon glyphicon-plus':
            changedClasses = true;
            iconNodes[i].className = 'fa fa-plus json-schema-editor-icon';
            break;
          case 'glyphicon glyphicon-arrow-up':
            changedClasses = true;
            iconNodes[i].className = 'fa fa-arrow-up json-schema-editor-icon';
            break;
          case 'glyphicon glyphicon-arrow-down':
            changedClasses = true;
            iconNodes[i].className = 'fa fa-arrow-down json-schema-editor-icon';
            break;
          default:
            // do nothing
        }
      }
    }
    if(changedClasses) {
      this.handleReplaceGlyphicons();
    }
  }

  handleChangeForm(e) {
    this.props.update(e.formData);
    this.handleReplaceGlyphicons();
  }

  render() {
    let title = this.props.title ? this.props.title : 'Array';

    let formData = this.props.data;
    if (typeof formData === 'string') formData = JSON.parse(formData);

    let listOfSheets = this.props.crisisSheetIds;
    let listOfSheetValues = [''];
    let listOfTitles = ['Select a crisis sheet'];

    listOfSheets.forEach(sheet => {
      let sheetName = JSON.stringify(sheet.providerLevel) + ' - ' + sheet.title;
      listOfSheetValues.push(`{ "id": "${sheet.id}", "name": "${sheetName}" }`);
      listOfTitles.push(sheetName);
    });

    const dataSchema = {
      title: title,
      type: 'array',
      items: {
        type: 'object',
        properties: {
          description: {
            type: 'string',
            default: ''
          },
          linkedSheets: {
            type: 'array',
            items: {
              type: 'string',
              default: 'Select a crisis sheet',
              enum: listOfSheetValues,
              enumNames: listOfTitles
            }
          }
        }
      }
    };

    const uiSchema = {
      classNames: 'json-schema-editor-container',
      'ui:options': {
        orderable: true,
        addable: true,
        removable: true
      },
      items: {
        classNames: 'json-schema-editor-instructions',
        description: {
          classNames: 'json-schema-editor-instructions-description',
          'ui:title': 'Step Description',
          'ui:field': 'markdown'
        },
        linkedSheets: {
          classNames: 'json-schema-editor-instructions-linked-id',
          'ui:title': 'Linked Crisis Sheets',
          items: {
            'ui:options': {
              orderable: true,
              addable: true,
              removable: true
            }
          }
        }
      }
    };

    const fields = {
      markdown: MarkdownField
    };

    return (
      <Form schema={ dataSchema }
        uiSchema={ uiSchema }
        formData={ formData }
        fields={fields}
        onChange={ this.handleChangeForm.bind(this) }>

        <div style={{ display: 'none' }} id='no-json-schema-default-buttons'></div>

      </Form>
    );
  }
}
