import React, { useState, useEffect } from 'react';
import { API }                        from 'aws-amplify';
import ReactMde                       from 'react-mde';
import Showdown                       from 'showdown';
import { CircularProgress }           from '@material-ui/core';

import Button    from '../../components/button';
import PageTitle from '../../components/page-title';
import Toolbar   from '../../components/toolbar';
import Snackbar  from '../../components/snackbar';

import 'react-mde/lib/styles/css/react-mde-all.css';
import './styles.css';

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

const Appendix = ({ history }) => {

  const [appendixValue, setAppendixValue] = useState('');
  const [currTab, setCurrTab] = useState('write');
  const [actionLoading, setActionLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState('');

  useEffect(() => {
    API.get('TenMinSavesAPI', '/v1/admin/appendix')
      .then(res => {
        setLoading(false);
        // check if an appendix was returned
        if(res.data.length > 0) {
          setAppendixValue(res.data[0].content);
        }
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setSnackbar(err.message);
      });
  }, []);

  function handleSaveAppendix() {
    setActionLoading(true);

    const params = {
      body: {
        content: appendixValue
      }
    };
    API.post('TenMinSavesAPI', '/v1/admin/appendix', params)
      .then(_ => {
        setActionLoading(false);
        setSnackbar('Appendix successfully updated');
      })
      .catch(err => {
        console.error(err);
        setActionLoading(false);
        setSnackbar(err.message);
      });
  }

  return(
    <>
      {
        snackbar.length > 0 &&
          <Snackbar
            message={snackbar}
            handleclose={() => setSnackbar('')} />
      }

      <Toolbar history={history} />

      <div className='page-max-width' style={{paddingLeft: 16, paddingRight: 16}}>

        <PageTitle text='Edit Appendix' icon={<i className='fa fa-book' style={{ fontSize: 42 }} />} />

        {
          loading ?
            <div style={{textAlign: 'center'}}>
              <CircularProgress size={100} />
            </div>
            :
            <>
              <ReactMde
                onChange={(value) => setAppendixValue(value)}
                value={appendixValue}
                onTabChange={setCurrTab}
                selectedTab={currTab}
                generateMarkdownPreview={markdown =>
                  Promise.resolve(converter.makeHtml(markdown))
                } />

              <div className='appendix-button'>
                <Button
                  text='SAVE'
                  onClick={handleSaveAppendix}
                  disabled={actionLoading}
                  color='primary'
                  size='large' />
              </div>
            </>
        }
 
      </div>
    </>
  );
}

export default Appendix;