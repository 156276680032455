const styles = (theme) => ({
  root: {
    width: "100%",
    height: 106,
  },
  button: {
    position: "fixed",
    bottom: 20,
    right: 20,
  },
});

export default styles;
