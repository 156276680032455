import React, { Component } from 'react';
import { API }              from 'aws-amplify';

import Button from '../button';
import Dialog from '../dialog';

import './auth-code-view.css';

export default class AuthCodeView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  handleDisableCode(e) {
    e.preventDefault();

    this.setState({ loading: true }, () => {
      let params = {
        body: {
          id: this.props.codedata.id
        }
      };
      API.post('TenMinSavesAPI', '/v1/admin/auth-code/revoke', params)
        .then(response => {
          if(response.message === 'Success') {
            this.props.onClose();
            this.props.refreshdata();
            this.setState({ loading: false });
          } else {
            this.setState({ loading: false });
            this.props.setsnackbar(response.message);
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ loading: false });
          this.props.setsnackbar(err.message);
        });
    });
  }

  render() {
    const data = this.props.codedata;

    if(data) {
      let listOfDevices = 'None';
      if(data.deviceTokens.length > 0) {
        listOfDevices = data.deviceTokens.map(token => {
          return (<div style={{padding: '5px 0'}} key={token}>{ token }</div>);
        });
      }

      const date = new Date(this.props.codedata.expirationDate);
      const expirationDate = (date.getMonth() + 1) + ' / ' + date.getDate() + ' / ' + date.getFullYear();

      return (
        <Dialog title={this.props.codedata.email} {...this.props}>

          <div className='auth-code-view-container'>
            <span className='auth-code-view-title'>
              {'Authorization Code:'}
            </span>
            <span className='auth-code-view-text'>
              { this.props.codedata.id }
            </span>
          </div>

          <div className='auth-code-view-container'>
            <span className='auth-code-view-title'>
              {'Authorized Devices:'}
            </span>
            <span className='auth-code-view-text'>
              { listOfDevices }
            </span>
          </div>

          <div className='auth-code-view-container'>
            <span className='auth-code-view-title'>
              {'Device Limit:'}
            </span>
            <span className='auth-code-view-text'>
              { this.props.codedata.deviceLimit === -1 ? 'Unlimited' : this.props.codedata.deviceLimit }
            </span>
          </div>

          <div className='auth-code-view-container'>
            <span className='auth-code-view-title'>
              {'Expiration Date:'}
            </span>
            <span className='auth-code-view-text'>
              { expirationDate }
            </span>
          </div>

          <div className='auth-code-view-container'>
            <span className='auth-code-view-title'>
              {'Status:'}
            </span>
            <span className='auth-code-view-text'>
              { this.props.codedata.status.toUpperCase() }
            </span>
          </div>

          <div className='auth-code-view-button'>
            <Button
              text='DISABLE CODE'
              onClick={this.handleDisableCode.bind(this)}
              color='secondary'
              disabled={this.state.loading || this.props.codedata.status === 'revoked'}
              size='large' />
          </div>

        </Dialog>
      );
    } else {
      return null;
    }
  }
}
