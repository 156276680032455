// React
import React from "react";

// Material UI
import { withStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";

// Material UI Icons
import AddIcon from "@material-ui/icons/Add";

// Styles
import styles from "./styles";

const FloatingAddButton = ({ classes, onClick, ...props }) => (
  <div className={classes.root}>
    <Button
      className={classes.button}
      variant="fab"
      color="primary"
      onClick={onClick}
      {...props}
    >
      <AddIcon />
    </Button>
  </div>
);

export default withStyles(styles)(FloatingAddButton);
