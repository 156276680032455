import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Appendix from "./containers/appendix";
import AuthCodes from "./containers/auth-codes";
import CrisisSheets from "./containers/crisis-sheets";
import CrisisSheetView from "./containers/CrisisSheetView";
import EmergencyDrugs from "./containers/emergency-drugs";
import ForgotPassword from "./containers/forgot-password";
import Login from "./containers/login";
import NewPassword from "./containers/new-password";
import PublicRoute from "./components/public-route";
import PrivateRoute from "./components/private-route";

export default class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            render={() => <PublicRoute component={Login} />}
          />
          <Route
            path="/forgot-password"
            exact
            render={() => <PublicRoute component={ForgotPassword} />}
          />
          <Route
            path="/new-password"
            exact
            render={() => <PublicRoute component={NewPassword} />}
          />
          <Route
            path="/auth-codes"
            exact
            render={() => <PrivateRoute component={AuthCodes} />}
          />
          <Route
            path="/crisis-sheets"
            exact
            render={() => <PrivateRoute component={CrisisSheets} />}
          />
          <Route
            path="/crisis-sheet"
            exact
            render={() => <PrivateRoute component={CrisisSheetView} />}
          />
          <Route
            path="/emergency-drugs"
            exact
            render={() => <PrivateRoute component={EmergencyDrugs} />}
          />
          <Route
            path="/appendix"
            exact
            render={() => <PrivateRoute component={Appendix} />}
          />
          <Route path="/*" render={() => <Redirect to="/" />} />
        </Switch>
      </BrowserRouter>
    );
  }
}
