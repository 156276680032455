export default {
  palette: {
    custom: {
      blue: "#3f51b5",
      darkGrey: "rgba(0, 0, 0, 0.54)",
    },
  },
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 500,
        width: "95%",
        maxHeight: "80%",
      },
      paper: {
        margin: "unset",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "24px 24px 0",
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px 6px 4px 14px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      head: {
        fontSize: 15,
      },
      body: {
        fontSize: 14,
      },
    },
    MuiSvgIcon: {
      root: {
        width: "2em",
      },
    },
  },
};
