// AWS Project Constants
const awsmobile = {
    Auth: {
        identityPoolId: 'us-west-2:6f2b4d6e-f762-4fee-9bed-d261bb73cfe0',
        region: 'us-west-2',
        userPoolId: 'us-west-2_cijHI8FpG',
        userPoolWebClientId: '12b7g0cl8qqpu1uvthhv5rhp00',
    },
    API: {
        endpoints: [
            {
                name: 'TenMinSavesAPI',
                endpoint: 'https://dev.api.tenminutesaves.com',
                region: 'us-west-2'
            }
        ]
    }
}

export default awsmobile;