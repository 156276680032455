import React, { Component } from 'react';

import './floating-form.css';

export default class FloatingForm extends Component {
  render() {
    return (
      <div className='floating-form-container'>

        { this.props.title ? <h2>{this.props.title}</h2> : '' }

        <form className='floating-form' onSubmit={this.props.onSubmit}>
          { this.props.children }
        </form>

      </div>
    );
  }
}
