import React, { Component } from "react";
import Tree from "react-d3-tree";

import CrisisSheetNodeEdit from "../crisis-sheet-node-edit";
import { findObjectById } from "../../functions/find-item-by-id.js";
import { findParentByAtt } from "../../functions/find-parent-by-att.js";
import { findParentByNode } from "../../functions/find-parent-by-node.js";
import { uuidv4 } from "../../functions/uuid-v4.js";

import "./crisis-sheet-diagram.css";

export default class CrisisSheetDiagram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editNode: null,
    };
  }

  componentDidMount() {
    if (this.props.data && JSON.stringify(this.props.data) === "{}") {
      this.createBasicDiagram();
    }
  }

  createBasicDiagram() {
    this.props.update([
      {
        name: "Mild or Major?",
        attributes: {
          id: uuidv4(),
          description: "How bad is it?",
          instructions: "[]",
        },
        children: [],
      },
    ]);
  }

  handleEditNode(nodeData, evt) {
    this.setState({ editNode: nodeData });
  }

  handleCloseEditNode() {
    this.setState({ editNode: null });
  }

  handleDeleteNode(nodeId) {
    let data = this.props.data;
    let foundNode = findObjectById(data, nodeId);
    let parentNode = findParentByAtt(data, foundNode);
    let grandparentNode = findParentByNode(data, parentNode);
    // if the node has a parent, let's delete it
    if (grandparentNode) {
      for (let i = 0; i < grandparentNode.children.length; i++) {
        if (
          JSON.stringify(parentNode) ===
          JSON.stringify(grandparentNode.children[i])
        ) {
          grandparentNode.children.splice(i, 1);
        }
      }
    }

    this.props.update(data);
    this.handleCloseEditNode();
  }

  handleAddNewDecision(nodeId) {
    let data = this.props.data;
    let foundNode = findObjectById(data, nodeId);
    let parentNode = findParentByAtt(data, foundNode);

    const newChildObj = {
      name: "New Decision",
      attributes: {
        id: uuidv4(),
        instructions: "[]",
      },
    };

    if (parentNode.children) {
      if (parentNode.children.length < 2) {
        parentNode.children.push(newChildObj);
      } else {
        this.props.snackbarMessage(
          "Each Instruction may have UP TO 2 decisions."
        );
      }
    } else {
      parentNode.children = [newChildObj];
    }

    this.props.update(data);
    this.handleCloseEditNode();
  }

  handleSaveNodeData(node) {
    let data = this.props.data;
    let foundNode = findObjectById(data, node.id);
    let parentNode = findParentByAtt(data, foundNode);

    parentNode.name = node.title;
    parentNode.attributes.description = node.description;
    parentNode.attributes.instructions = node.instructions;

    this.props.update(data);
    this.handleCloseEditNode();
  }

  render() {
    const { data } = this.props;

    let initialScreenWidth = window.innerWidth;
    let offsetXAxis = 400;

    if (initialScreenWidth < 850) offsetXAxis = initialScreenWidth / 2;

    if (
      !this.props.data ||
      (this.props.data && JSON.stringify(this.props.data) === "{}")
    ) {
      return null;
    }

    return (
      <div className="crisis-sheet-diagram-container">
        <CrisisSheetNodeEdit
          crisisSheetIds={this.props.crisisSheetIds}
          node={this.state.editNode}
          open={this.state.editNode ? true : false}
          onClose={this.handleCloseEditNode.bind(this)}
          savenodedata={this.handleSaveNodeData.bind(this)}
          deletenode={this.handleDeleteNode.bind(this)}
          addnewdecision={this.handleAddNewDecision.bind(this)}
        />

        <Tree
          data={data}
          onClick={this.handleEditNode.bind(this)}
          orientation="vertical"
          translate={{ x: offsetXAxis, y: 50 }}
          collapsible={false}
          zoomable={true}
          scaleExtent={{ max: 1, min: 0.5 }}
          transitionDuration={0}
          separation={{ siblings: 1, nonSiblings: 1 }}
          textLayout={{
            textAnchor: "middle",
            x: 0,
            y: 25,
          }}
          styles={{
            links: {
              stroke: "var(--faded-blue)",
              strokeWidth: 3,
            },
            nodes: {
              node: {
                circle: {
                  fill: "#c9cde1",
                  stroke: "var(--blue)",
                  strokeWidth: 3,
                },
                name: {
                  transform: "scale(1.1)",
                },
                attributes: {
                  display: "none",
                },
              },
              leafNode: {
                circle: {
                  fill: "#c9cde1",
                  stroke: "var(--blue)",
                  strokeWidth: 3,
                },
                name: {
                  transform: "scale(1.1)",
                },
                attributes: {
                  display: "none",
                },
              },
            },
          }}
        />
      </div>
    );
  }
}
