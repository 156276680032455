// ============================================
// recursive function for finding grandparent object by passing the child node
// ============================================

export const findParentByNode = function(searchObject, searchData) {

  var result = null;

  if(searchObject instanceof Array) {
    for(var i = 0; i < searchObject.length; i++) {
      result = findParentByNode(searchObject[i], searchData);
      if (result) {
        break;
      }
    }
  }
  else {
    for(var property in searchObject) {
      // console.log(property + ': ' + searchObject[property]);
      if(property === 'children') {
        for(let i = 0; i < searchObject[property].length; i++) {
          if(JSON.stringify(searchObject[property][i]) === JSON.stringify(searchData)) {
            return searchObject;
          }
        }
      }
      if(searchObject[property] instanceof Object || searchObject[property] instanceof Array) {
        result = findParentByNode(searchObject[property], searchData);
        if (result) {
          break;
        }
      }
    }
  }
  // by this point, we found the grandparent object!
  return result;
}
