import React, { Component } from 'react';
import Form                 from 'react-jsonschema-form';
import _cloneDeep           from 'lodash.clonedeep';

import './EmergencyDrugsAttached.css';

export default class EmergencyDrugsAttached extends Component {
  constructor(props) {
    super(props);

    this.handleReplaceGlyphicons = this.handleReplaceGlyphicons.bind(this);
  }

  componentDidMount() {
    this.handleReplaceGlyphicons();
  }

  handleReplaceGlyphicons() {
    // replace glyphicons with font-awesome icons
    let iconNodes = document.getElementsByClassName('glyphicon');
    let length = iconNodes.length;
    let changedClasses = false;

    for(let i = 0; i < length; i ++) {
      if(iconNodes[i]) {
        switch(iconNodes[i].className) {
          case 'glyphicon glyphicon-remove':
            changedClasses = true;
            iconNodes[i].className = 'fa fa-trash json-schema-editor-icon';
            break;
          case 'glyphicon glyphicon-plus':
            changedClasses = true;
            iconNodes[i].className = 'fa fa-plus json-schema-editor-icon';
            break;
          case 'glyphicon glyphicon-arrow-up':
            changedClasses = true;
            iconNodes[i].className = 'fa fa-arrow-up json-schema-editor-icon';
            break;
          case 'glyphicon glyphicon-arrow-down':
            changedClasses = true;
            iconNodes[i].className = 'fa fa-arrow-down json-schema-editor-icon';
            break;
          default:
            // do nothing
        }
      }
    }
    if(changedClasses) {
      this.handleReplaceGlyphicons();
    }
  }

  handleChangeForm(e) {
    this.props.update_drugs(e.formData);
    this.handleReplaceGlyphicons();
  }

  render() {
    let formData = this.props.data;
    if (typeof formData === 'string') formData = JSON.parse(formData);

    let listOfDrugs = _cloneDeep(this.props.emergency_drugs);
    let listOfDrugValues = [''];
    let listOfTitles = ['Select an emergency drug'];

    if(listOfDrugs) {
      listOfDrugs.sort((a, b) => {
        if(a.name < b.name) return -1;
        if(a.name > b.name) return 1;
        return 0;
      });

      listOfDrugs.forEach(drug => {
        listOfDrugValues.push(JSON.stringify(drug));
        listOfTitles.push(drug.name);
      });
    }

    const dataSchema = {
      title: 'Attached Emergency Drug(s)',
      type: 'array',
      items: {
        type: 'string',
        default: 'Select an emergency drug',
        enum: listOfDrugValues,
        enumNames: listOfTitles
      }
    };

    const uiSchema = {
      classNames: 'json-schema-editor-container emergency-drugs-json-style',
      'ui:options':  {
        orderable: true,
        addable: true,
        removable: true
      },
      items: {
        classNames: 'json-schema-editor-instructions-linked-id',
        items: {
          'ui:options':  {
            orderable: true,
            addable: true,
            removable: true
          }
        }
      }
    };

    return (
      <div className='EmergencyDrugsAttached-view'>
        <Form schema={ dataSchema }
          uiSchema={ uiSchema }
          formData={ formData }
          onChange={ this.handleChangeForm.bind(this) }
        >

          <div style={{ display: 'none' }} id='no-json-schema-default-buttons'></div>

        </Form>
      </div>
    );
  }
}
