import React, { Component } from 'react';
import CloseIcon            from '@material-ui/icons/Close';
import DialogTitle          from '@material-ui/core/DialogTitle';
import Dialog               from '@material-ui/core/Dialog';

import './dialog.css';

export default class StyledDialog extends Component {
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>

        <DialogTitle>
          <div className='dialog-title-container'>
            <div className='dialog-title-text'>
              { this.props.title }
            </div>

            <div onClick={() => this.props.onClose()}>
              <CloseIcon className='dialog-close-icon' />
            </div>
          </div>
        </DialogTitle>

        <div className='dialog-body-container'>

          { this.props.children }

        </div>
      </Dialog>
    );
  }
}
