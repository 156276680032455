import React, { Component } from 'react';

import './page-header.css';

export default class PageHeader extends Component {
  render() {
    return (
      <div className='page-header-container'>
        <span className='page-header-text'>
          {'10 Minutes Saves a Life'}
        </span>
      </div>
    );
  }
}
