import { API } from "aws-amplify";

const getUser = async (sub) => {
  try {
    return await API.get("TenMinSavesAPI", `/v1/admin/users/${sub}`);
  } catch (err) {
    throw new Error(err);
  }
};

if (process.env.NODE_ENV !== "production")
  window.getUser = async (sub) => await getUser(sub);

export default getUser;
