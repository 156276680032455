// React
import React, { Component } from "react";

// AWS
import { API } from "aws-amplify";

// Libraries
import ISO6391 from "iso-639-1";

// Redux
import { connect } from "react-redux";

// Material UI
import CrisisSheetIcon from "@material-ui/icons/Assignment";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// Components
import CustomSelect from "../../components/CustomSelect";
import FloatingAddButton from "../../components/FloatingAddButton";
import PageTitle from "../../components/page-title";
import Snackbar from "../../components/snackbar";
import Table from "../../components/table";
import Toolbar from "../../components/toolbar";
import ToxicitySheetSelectors from "./ToxicitySheetSelectors";

// Styles
import "./crisis-sheets.css";

const ITEMS_PER_PAGE = 100;

class CrisisSheets extends Component {
  constructor(props) {
    super(props);

    const allLanguages = this.setAvailableLanguages();
    const language = "English"; //allLanguages.length ? allLanguages[0].value : "";

    this.state = {
      page: 0,
      rows: ITEMS_PER_PAGE,
      totalCount: ITEMS_PER_PAGE,
      listOfSheets: [],
      loading: false,
      snackbarMessage: "",
      getListIds: false,
      language,
      allLanguages,
    };
  }

  componentDidMount() {
    if (this.state.language)
      this.getListOfCrisisSheets({
        language: ISO6391.getCode(this.state.language),
      });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.language !== this.state.language) {
      this.getListOfCrisisSheets({
        language: ISO6391.getCode(this.state.language),
      });
    }
  }

  setAvailableLanguages() {
    if (this.props.user.languages.includes("*")) {
      return ISO6391.getAllNames().map((language) => ({
        value: language,
        label: language,
      }));
    }

    return this.props.user.languages.map((code) => ({
      value: ISO6391.getName(code),
      label: ISO6391.getName(code),
    }));
  }

  getListOfCrisisSheets(config) {
    this.setState({ loading: true }, () => {
      let params = {
        body: {
          page: this.state.page,
          count: ITEMS_PER_PAGE,
          ...config,
        },
      };

      API.post("TenMinSavesAPI", "/v1/admin/crisis-sheet/list", params)
        .then((response) => {
          if (response.message === "Success") {
            this.setState({
              listOfSheets: response.data,
              loading: false,
              totalCount: response.totalItems,
              getListIds: true,
            });
          } else {
            this.setState({
              listOfSheets: null,
              loading: false,
              snackbarMessage: "Error loading sheets",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            snackbarMessage: "Error loading sheets",
            loading: false,
          });
        });
    });
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      if (!!event) {
        this.getListOfCrisisSheets({ language: this.state.language });
      }
    });
  };

  onChange = (field, e) => {
    this.setState({ [field]: e.target.value });
  };

  handleViewCrisisSheet(crisisSheetId, version) {
    this.props.history.push(
      `/crisis-sheet?id=${crisisSheetId}&version=${version}`
    );
  }

  handleAddCrisisSheet() {
    this.props.history.push("/crisis-sheet");
  }

  closeSnackbar() {
    this.setState({ snackbarMessage: "" });
  }

  render() {
    const headers = [
      { title: "Crisis Name" },
      { title: "Provider Level" },
      { title: "Short Description" },
      { title: "Latest Version" },
      { title: "Order" },
    ];

    const {
      rows,
      page,
      listOfSheets,
      totalCount,
      loading,
      getListIds,
      language,
      allLanguages,
    } = this.state;

    return (
      <div>
        {this.state.snackbarMessage.length > 0 && (
          <Snackbar
            message={this.state.snackbarMessage}
            handleclose={this.closeSnackbar.bind(this)}
          />
        )}

        <Toolbar history={this.props.history} />

        <div className="page-max-width">
          <PageTitle
            text="Manage Crisis Sheets"
            icon={<CrisisSheetIcon style={{ height: 42, width: 42 }} />}
          />

          <ToxicitySheetSelectors
            makeCall={getListIds}
            setSnackbar={(snackbarMessage) =>
              this.setState({ snackbarMessage })
            }
            language={this.state.language}
          />

          <div className="crisis-sheet-table-width">
            <Paper>
              <div className="select-language">
                <CustomSelect
                  label="Select Language"
                  onChange={this.onChange.bind(this, "language")}
                  value={language}
                  options={allLanguages}
                />
              </div>
              <Table
                headers={headers}
                tablepagination={
                  <TableRow>
                    <TablePagination
                      colSpan={headers.length}
                      count={totalCount}
                      rowsPerPage={rows}
                      rowsPerPageOptions={[rows]}
                      page={page || 0}
                      backIconButtonProps={{ "aria-label": "Previous Page" }}
                      nextIconButtonProps={{ "aria-label": "Next Page" }}
                      onChangePage={this.handleChangePage}
                    />
                  </TableRow>
                }
              >
                {!loading ? (
                  listOfSheets && listOfSheets.length > 0 ? (
                    listOfSheets.map((crisisSheet) => {
                      return (
                        <TableRow key={crisisSheet.id}>
                          <TableCell>
                            <span
                              onClick={this.handleViewCrisisSheet.bind(
                                this,
                                crisisSheet.id,
                                crisisSheet.latestVersion,
                                this.state.language
                              )}
                              className="link-text"
                            >
                              {crisisSheet.title}
                            </span>
                          </TableCell>
                          <TableCell>{crisisSheet.providerLevel}</TableCell>
                          <TableCell>{crisisSheet.shortDescription}</TableCell>
                          <TableCell>{crisisSheet.latestVersion}</TableCell>
                          <TableCell>{crisisSheet.order}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={headers.length}>
                        {"No Crisis Sheets found..."}
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length}>
                      <div style={{ textAlign: "center" }}>
                        <CircularProgress size={100} />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </Paper>

            <FloatingAddButton onClick={this.handleAddCrisisSheet.bind(this)} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(CrisisSheets);
