import React, { Component } from 'react';
import Button               from '@material-ui/core/Button';

import './button.css';

export default class StyledButton extends Component {
  render() {
    return (
      <Button variant='contained' {...this.props}>
        {this.props.text}
      </Button>
    );
  }
}
