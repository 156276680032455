// ============================================
// recursive function for finding an object by key 'id'
// ============================================

export const findObjectById = function(searchObject, searchId) {

  var result = null;

  if(searchObject instanceof Array) {
    for(var i = 0; i < searchObject.length; i++) {
      result = findObjectById(searchObject[i], searchId);
      if (result) {
        break;
      }
    }
  }
  else {
    for(var property in searchObject) {
      // console.log(property + ': ' + searchObject[property]);
      if(property === 'id') {
        if(searchObject[property] === searchId) {
          return searchObject;
        }
      }
      if(searchObject[property] instanceof Object || searchObject[property] instanceof Array) {
        result = findObjectById(searchObject[property], searchId);
        if (result) {
          break;
        }
      }
    }
  }
  // by this point, we found the 'attributes' object,
  // but we need the parent to edit name/add children
  return result;
}
