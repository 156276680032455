import validator from 'validator';

export const validateField = function(field, e, state) {
  let value = e.target.value;
  let valid;

  switch (field) {
    case 'email':
      valid = validator.isEmail(value)
      break;
    case 'password':
      valid = validator.matches(value, /(?=^.{12,255}$)((?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=(.*\d){1,}))((?!.*[",;&|'])|(?=(.*\W){1,}))(?!.*[",;&|'])^.*$/)
      break;
    case 'passwordMatch':
      valid = (value === state.password.value)
      break;
    case 'code':
      valid = (value.length === 6);
      break;
    case 'providerLevel':
      // eslint-disable-next-line
      valid = (value == 1 || value == 2 || value == 3);
      break;
    case 'evaluationLevel':
      // eslint-disable-next-line
      valid = (value == 1 || value == 2 || value == 3);
      break;
    case 'title':
    case 'note':
      valid = true;
      break;
    default:
      valid = (value.length >= 1);
      break;
  }

  return {
    value: value,
    valid: valid
  }
};
