// React
import React from "react";
import ReactDOM from "react-dom";

// Redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/config";

// AWS
import { Amplify, API } from "aws-amplify";
import { awsConfig } from "./aws";

// Service Worker
import registerServiceWorker from "./registerServiceWorker";

// Routes
import Router from "./router.js";

// Material UI
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Styles
import "./index.css";
import styles from "./styles.js";

Amplify.configure(awsConfig);
API.configure(awsConfig.API);

const App = (props) => {
  const muiTheme = createMuiTheme(styles);

  return (
    <Provider store={store}>
      <PersistGate
        loading={<CircularProgress size={150} />}
        persistor={persistor}
      >
        <MuiThemeProvider theme={muiTheme}>
          <Router />
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

registerServiceWorker();
