import awsConfigLocal from './aws-config-local';
import awsConfigStage from './aws-config-dev';
import awsConfigProd from './aws-config-master';

let awsConfig;
// load the correct aws config options
switch(process.env.REACT_APP_ENVIRONMENT) {
  case 'prod':
    awsConfig = awsConfigProd;
  break;
  case 'dev':
    awsConfig = awsConfigStage;
  break;
  default:
    awsConfig = awsConfigLocal;
}
export {
    awsConfig
}