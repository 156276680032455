import { API } from 'aws-amplify';

class TenMinutes {
    /**
     * Crisis Sheet Methods
     */

    static async createCrisisSheet(body) {
        const response = await API.post('TenMinSavesAPI', '/v1/admin/crisis-sheet/create', { body });

        return response;
    }

    static async getCrisisSheet(id, version = 0) {
        const response = await API.get('TenMinSavesAPI', `/v1/admin/crisis-sheet/?id=${id}&version=${version}`);

        return response.data;
    }

    static async getCrisisSheets(myInit = {}) {
        const response = await API.post('TenMinSavesAPI', '/v1/admin/crisis-sheet/list', myInit);

        return response.data;
    }

    static async updateCrisisSheet(body) {
        const response = await API.post('TenMinSavesAPI', '/v1/admin/crisis-sheet/update', { body });
        
        return response;
    }

    static async getLanguages() {
        const response = await API.get('TenMinSavesAPI', '/v1/admin/languages');

        const languages = response.data.map(language => {
            return {
                label: language.label,
                value: language.languageCode
            }
        });

        return languages; 
    }

    /**
     * Emergency Drug Methods
     */

    static async getEmergencyDrugList(myInit = {}) {
        const response = await API.post('TenMinSavesAPI', '/v1/admin/emergency-drugs/list', myInit);

        if (response.message === 'Success') {
            // now we need to attach the provider level to the
            // emergency drug name for showing in the list
            const emergencyDrugs = response.data.map(drug => {
              return {
                ...drug,
                name: `${drug.name} (${drug.providerLevel})`
              };
            });
  
            return emergencyDrugs;
        } else {
            throw new Error(response);
        }
    }
}

export default TenMinutes;