import React, { Component } from 'react';
import TextField            from '@material-ui/core/TextField';

import './input.css';

export default class Input extends Component {
  render() {
    return (
      <TextField
        type='text'
        {...this.props}
        fullWidth
        margin='normal'
        helperText={this.props.errortext}
        error={this.props.errortext.length > 0} />
    );
  }
}
