import React, { Component } from 'react';
import AppBar               from '@material-ui/core/AppBar';
import ArrowBackIcon        from '@material-ui/icons/ArrowBack';
import IconButton           from '@material-ui/core/IconButton';
import MenuIcon             from '@material-ui/icons/Menu';
import Toolbar              from '@material-ui/core/Toolbar';
import _debounce            from 'lodash.debounce';

import Drawer from '../drawer';

import './toolbar.css';

export default class StyledToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDrawer: false
    };

    this.debouncedToggleDrawer = _debounce(this.toggleDrawer, 250, { leading: true, trailing: false }).bind(this);
  }

  toggleDrawer() {
    this.setState({ openDrawer: !this.state.openDrawer });
  }

  handleBackButton() {
    if(this.props.route) {
      this.props.history.push(this.props.route);
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    return (
      <AppBar position='static'>
        <div className='toolbar-max-width'>
          <Toolbar variant='dense'>

            {
              this.props.backbutton ?
                <IconButton color='inherit' aria-label='Back' onClick={this.handleBackButton.bind(this)}>
                  <ArrowBackIcon />
                </IconButton>
                :
                <IconButton color='inherit' aria-label='Menu' onClick={this.debouncedToggleDrawer}>
                  <MenuIcon />
                </IconButton>
            }

            <span className='toolbar-title-text'>
              {'10 Minutes Saves a Life'}
            </span>

            <Drawer
              history={this.props.history}
              open={this.state.openDrawer}
              onClose={this.debouncedToggleDrawer} />

          </Toolbar>
        </div>
      </AppBar>
    );
  }
}
