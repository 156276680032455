// React
import React, { Component } from "react";

// AWS
import { Auth } from "aws-amplify";

// Redux
import { connect } from "react-redux";
import { clearUser } from "../../redux/actions/user";

// Material UI
import AuthCodeIcon from "@material-ui/icons/VerifiedUser";
import CloseIcon from "@material-ui/icons/Close";
import CrisisSheetIcon from "@material-ui/icons/Assignment";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import LogoutIcon from "@material-ui/icons/SubdirectoryArrowLeft";

// Style
import "./drawer.css";

class StyledDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.history.location.pathname,
    };
  }

  handleNavigation(route) {
    this.props.history.push(route);
  }

  async handleLogout() {
    this.props.clearUser();
    await Auth.signOut();
    this.props.history.push("/");
  }

  render() {
    return (
      <Drawer anchor="left" {...this.props}>
        <div className="blue-header-container">
          <div className="drawer-close-button">
            <IconButton
              color="inherit"
              aria-label="Close"
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <span className="blue-header-container-text">{"Admin Panel"}</span>
        </div>

        <div
          onClick={this.handleNavigation.bind(this, "/auth-codes")}
          className={
            "drawer-item" +
            (this.state.active === "/auth-codes" ? " drawer-item-active" : "")
          }
        >
          <AuthCodeIcon className="drawer-item-icon" />

          {"Authorization Codes"}
        </div>

        <div
          onClick={this.handleNavigation.bind(this, "/crisis-sheets")}
          className={
            "drawer-item" +
            (this.state.active === "/crisis-sheets"
              ? " drawer-item-active"
              : "")
          }
        >
          <CrisisSheetIcon className="drawer-item-icon" />

          {"Crisis Sheets"}
        </div>

        <div
          onClick={this.handleNavigation.bind(this, "/emergency-drugs")}
          className={
            "drawer-item" +
            (this.state.active === "/emergency-drugs"
              ? " drawer-item-active"
              : "")
          }
        >
          <i
            className="fa fa-medkit drawer-item-icon"
            style={{ paddingLeft: 2 }}
          />

          {"Emergency Drugs"}
        </div>

        <div
          onClick={this.handleNavigation.bind(this, "/appendix")}
          className={
            "drawer-item" +
            (this.state.active === "/appendix" ? " drawer-item-active" : "")
          }
        >
          <i
            className="fa fa-book drawer-item-icon"
            style={{ paddingLeft: 2 }}
          />

          {"Appendix"}
        </div>

        <div onClick={this.handleLogout.bind(this)} className="drawer-item">
          <LogoutIcon className="drawer-item-icon" />

          {"Logout"}
        </div>
      </Drawer>
    );
  }
}

export default connect(null, { clearUser })(StyledDrawer);
