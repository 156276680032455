import React, { Component } from "react";
import { API } from "aws-amplify";
import ISO6391 from "iso-639-1";

import { validateForm } from "../../functions/validate-form.js";
import { validateField } from "../../functions/validate-field.js";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Button from "../button";
import CustomSelect from "../../components/CustomSelect";
import ConfirmDialog from "../confirm-dialog";
import Dialog from "../dialog";
import Input from "../input";

import "./emergency-drug-create.css";

export default class EmergencyDrugCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: { value: "", valid: true },
      note: { value: "", valid: true },
      dosageAmount: { value: "", valid: true },
      dosageUnit: { value: "", valid: true },
      information: { value: "", valid: true },
      secondDosage: { value: "", valid: true },
      providerLevel: { value: "", valid: true },
      language: {
        value: props.language ? props.language : "English",
        valid: true,
      },
      allLanguages: ISO6391.getAllNames().map((language) => ({
        value: language,
        label: language,
      })),
      useSecondDosage: false,
      showConfirm: false,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.setState({ language: { value: this.props.language, valid: true } });
    }

    if (
      JSON.stringify(prevProps.drug_data) !==
      JSON.stringify(this.props.drug_data)
    ) {
      let state = Object.assign({}, this.state);

      if (this.props.drug_data) {
        let {
          id,
          language,
          name,
          note,
          dosageAmount,
          dosageUnit,
          information,
          secondDosage,
          providerLevel,
        } = this.props.drug_data;

        state.id = id;
        state.language.value = ISO6391.getName(language);
        state.name.value = name;
        state.note.value = note;
        state.dosageAmount.value = dosageAmount;
        state.dosageUnit.value = dosageUnit;
        state.information.value = information;
        state.secondDosage.value = secondDosage;
        state.providerLevel.value = providerLevel;

        if (secondDosage && secondDosage !== "") {
          state.useSecondDosage = true;
        } else {
          state.useSecondDosage = false;
        }

        this.setState(state);
      }
    }
  }

  handleChange(field, e) {
    let { value, valid } = validateField(field, e, this.state);

    this.setState({
      [field]: { value, valid },
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const {
      language,
      name,
      dosageUnit,
      dosageAmount,
      information,
      note,
      secondDosage,
      useSecondDosage,
      providerLevel,
    } = this.state;
    const { formValid, emptyFields } = validateForm({
      name,
      dosageUnit,
      dosageAmount,
      information,
      providerLevel,
    });

    if (formValid) {
      this.setState({ loading: true }, () => {
        let params = {
          body: {
            language: ISO6391.getCode(language.value),
            name: name.value,
            dosageUnit: dosageUnit.value,
            dosageAmount: dosageAmount.value,
            information: information.value,
            note: note.value,
            secondDosage: useSecondDosage ? secondDosage.value : "",
            providerLevel: providerLevel.value,
          },
        };

        if (this.props.drug_data) {
          API.post(
            "TenMinSavesAPI",
            `/v1/admin/emergency-drugs/update/${this.props.drug_data.id}`,
            params
          )
            .then((response) => {
              this.props.onClose();
              this.props.refreshdata();
              if (response.message === "Success") {
                this.setState({
                  language: { value: "", valid: true },
                  name: { value: "", valid: true },
                  note: { value: "", valid: true },
                  dosage: { value: "", valid: true },
                  information: { value: "", valid: true },
                  secondDosage: { value: "", valid: true },
                  providerLevel: { value: "", valid: true },
                  loading: false,
                });
              } else {
                this.setState({ loading: false });
                this.props.setsnackbar(response.message);
              }
            })
            .catch((err) => {
              console.error(err);
              this.props.setsnackbar(err.message);
              this.setState({ loading: false });
            });
        } else {
          API.post("TenMinSavesAPI", "/v1/admin/emergency-drugs/create", params)
            .then((response) => {
              this.props.onClose();
              this.props.refreshdata();
              if (response.message === "Success") {
                this.setState({
                  language: { value: "", valid: true },
                  name: { value: "", valid: true },
                  note: { value: "", valid: true },
                  dosage: { value: "", valid: true },
                  information: { value: "", valid: true },
                  secondDosage: { value: "", valid: true },
                  providerLevel: { value: "", valid: true },
                  loading: false,
                });
              } else {
                this.setState({ loading: false });
                this.props.setsnackbar(response.message);
              }
            })
            .catch((err) => {
              console.error(err);
              this.props.setsnackbar(err.message);
              this.setState({ loading: false });
            });
        }
      });
    } else {
      emptyFields.forEach((fieldName) => {
        this.setState({ [fieldName]: { value: "", valid: false } });
      });
    }
  }

  toggleSecondDosage() {
    const { useSecondDosage } = this.state;
    this.setState({ useSecondDosage: !useSecondDosage });
  }

  handleDelete() {
    this.setState({ showConfirm: true });
  }

  handleDeleteClose() {
    this.setState({ showConfirm: false });
  }

  handleDeleteConfirm() {
    const { id } = this.props.drug_data;

    API.del("TenMinSavesAPI", `/v1/admin/emergency-drugs?id=${id}`)
      .then((response) => {
        // take them back to the drugs list
        this.props.onClose();
        this.props.refreshdata();
      })
      .catch((err) => {
        console.log(err);
        this.props.setsnackbar(err.message);
      });
  }

  render() {
    const { showConfirm } = this.state;
    const { drug_data } = this.props;

    return (
      <Dialog
        title={drug_data ? "Edit Emergency Drug" : "Create New Emergency Drug"}
        {...this.props}
      >
        <ConfirmDialog
          open={showConfirm}
          onClose={this.handleDeleteClose.bind(this)}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        />

        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="select-language-create">
            <CustomSelect
              disabled={true}
              label="Select Language"
              onChange={this.handleChange.bind(this, "language")}
              value={this.state.language.value}
              options={this.state.allLanguages}
            />
          </div>

          <div>
            <Input
              id="name"
              label="Drug Name"
              onChange={this.handleChange.bind(this, "name")}
              value={this.state.name.value}
              errortext={
                this.state.name.valid ? "" : "Please enter a drug name."
              }
            />
          </div>

          <div>
            <Input
              id="providerLevel"
              label="Provider Level"
              type="number"
              onChange={this.handleChange.bind(this, "providerLevel")}
              value={this.state.providerLevel.value}
              errortext={
                this.state.providerLevel.valid
                  ? ""
                  : "Must be either 1, 2, or 3."
              }
            />
          </div>

          <div>
            <Input
              multiline
              id="note"
              label="Note"
              onChange={this.handleChange.bind(this, "note")}
              value={this.state.note.value}
              errortext={""}
            />
          </div>

          <div>
            <Input
              id="information"
              label="Information"
              onChange={this.handleChange.bind(this, "information")}
              value={this.state.information.value}
              errortext={
                this.state.information.valid
                  ? ""
                  : "Please enter drug information."
              }
            />
          </div>

          <div>
            <Input
              id="dosageUnit"
              label="Dosage Unit"
              onChange={this.handleChange.bind(this, "dosageUnit")}
              value={this.state.dosageUnit.value}
              errortext={
                this.state.dosageUnit.valid ? "" : "Please enter a dosage unit."
              }
            />
          </div>

          <div>
            <Input
              multiline
              id="dosageAmount"
              label="Dosage Amount"
              onChange={this.handleChange.bind(this, "dosageAmount")}
              value={this.state.dosageAmount.value}
              errortext={""}
            />

            <div className="dosage-function-text" style={{ paddingBottom: 5 }}>
              {"Enter a dosage function in javascript using 'w' for weight. "}
            </div>
            <div className="dosage-function-text">
              {"example: if(w>30){ 45 } else{ w*12 }"}
            </div>
          </div>

          <FormControlLabel
            label="Second Dosage"
            control={
              <Checkbox
                checked={this.state.useSecondDosage}
                onChange={this.toggleSecondDosage.bind(this)}
                value="second-dosage"
              />
            }
          />

          {this.state.useSecondDosage ? (
            <div>
              <Input
                multiline
                id="secondDosage"
                label="Second Dosage Amount"
                onChange={this.handleChange.bind(this, "secondDosage")}
                value={this.state.secondDosage.value}
                errortext={""}
              />

              <div
                className="dosage-function-text"
                style={{ paddingBottom: 5 }}
              >
                {"Enter a dosage function in javascript using 'w' for weight. "}
              </div>
              <div className="dosage-function-text">
                {"example: if(w>30){ 45 } else{ w*12 }"}
              </div>
            </div>
          ) : null}

          <input ref="formSubmit" type="submit" className="none" />

          <div className="emergency-drug-create-button-container">
            <Button
              text={drug_data ? "UPDATE EMERGENCY DRUG" : "ADD EMERGENCY DRUG"}
              onClick={this.handleSubmit.bind(this)}
              disabled={this.state.loading}
              color="primary"
              size="large"
            />
            {this.props.drug_data ? (
              <Button
                text="DELETE"
                onClick={this.handleDelete.bind(this)}
                color="secondary"
                size="large"
              />
            ) : null}
          </div>
        </form>
      </Dialog>
    );
  }
}
